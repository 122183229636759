export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Kode Customer *',
    dataIndex: 'code',
  },
  {
    title: 'Nama Customer *',
    dataIndex: 'name',
  },
  {
    title: 'Grup Pelanggan *',
    dataIndex: 'group_customer',
  },
  {
    title: 'Alamat *',
    dataIndex: 'address',
  },
  {
    title: 'Kode Wilayah',
    dataIndex: 'wilayah_id',
  },
  {
    title: 'Wilayah',
    dataIndex: 'wilayah_db',
  },
  // {
  //   title: 'Nomor Telepon *',
  //   dataIndex: 'phone',
  // },
  {
    title: 'Segment',
    dataIndex: 'segment_name',
  },
  {
    title: 'Sub Segment',
    dataIndex: 'sub_segment_name',
  },
  {
    title: 'Latitude',
    dataIndex: 'lat',
  },
  {
    title: 'Longitude',
    dataIndex: 'lng',
  },
  {
    title: 'Cluster *',
    dataIndex: 'cluster',
  },
  {
    title: 'KAM *',
    dataIndex: 'nopeg_kam',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
]
